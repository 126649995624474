.slider-container {
	@media only screen and (max-width: (@screen-sm)) {
		padding-bottom: 140px;
	}

	.slider {
		&[class~='slick-slider'] {
			position: relative;
			margin: -17px;
			.slide-container {
				padding: 17px;
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 0;
				z-index: 1;
				width: 13%;
				height: 100%;
			}
			&::before {
				left: 0;
				background: linear-gradient(90deg, #060606 8.73%, rgba(6, 6, 6, 0) 100%);
			}
			&::after {
				right: 0;
				background: linear-gradient(-90deg, #060606 8.73%, rgba(6, 6, 6, 0) 100%);
			}

			[class~='slick-arrow'] {
				z-index: 2;

				&[class~='slick-disabled'] {
					opacity: 0.6;
					cursor: no-drop;
				}
			}

			[class~='slick-prev'],
			[class~='slick-next'] {
				width: 66px;
				height: 132px;

				@media only screen and (max-width: (@screen-tablet-max)) {
					width: 66px;
					height: 132px;
				}

				@media only screen and (max-width: (@screen-mobile-max)) {
					width: 60px;
					height: 90px;
				}

				&::before {
					content: '';
					opacity: 1;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 6px;
					border: 0.5px solid #5c5c5c;
					background: rgba(217, 217, 217, 0.15);
					box-shadow:
						0px 0px 4px 0px rgba(255, 255, 255, 0.25) inset,
						0px 2px 2px 0px rgba(0, 0, 0, 0.48),
						0px 2px 2px 0px rgba(0, 0, 0, 0.48);
					backdrop-filter: blur(17px);
					background-size: 30%;
					background-repeat: no-repeat;
					background-position: center;
				}
			}

			[class~='slick-prev'] {
				left: 40px;

				@media only screen and (max-width: (@screen-tablet-max)) {
					left: 18px;
				}

				&::before {
					background-image: url('/icons/icon-arrow-left.svg');
				}
			}

			[class~='slick-next'] {
				right: 40px;

				@media only screen and (max-width: (@screen-tablet-max)) {
					right: 18px;
				}

				&::before {
					background-image: url('/icons/icon-arrow-right.svg');
				}
			}

			@media only screen and (max-width: (@screen-sm)) {
				[class~='slick-prev'],
				[class~='slick-next'] {
					top: calc(100% + 45px);
					transform: none;
				}

				[class~='slick-prev'] {
					left: calc(50% - 60px - 15px);
				}

				[class~='slick-next'] {
					right: calc(50% - 60px - 15px);
				}
			}
		}
	}
}

.card-game {
	position: relative;
	height: 0;
	width: 100%;
	padding-bottom: 150%;
	border-radius: 4px;

	.content-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		transition: transform 0.3s ease;

		.image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.button-group {
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;
			row-gap: 9px;
			padding: 15px;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 30%, #139efb 90%, #139efb 120%);
			animation-duration: 0.3s;
			display: none;

			@media only screen and (max-width: (@screen-sm)) {
				display: flex;
			}

			.button {
				width: 100%;
				height: 60px;
				border-radius: 4px;
				background: rgba(30, 30, 30, 0.8);
				color: @white;
				text-align: center;
				text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
				font-size: 14px;
				font-weight: 700;

				@media only screen and (max-width: (@screen-mobile-max)) {
					height: 48px;
				}
			}
		}

		&:hover {
			transform: scale(1.1);

			.button-group {
				display: flex;
			}
		}
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';