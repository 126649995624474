.banner-slider-container {
	padding-bottom: 55px;

	.slider {
		&[class~='slick-slider'] {
			margin: 0 -20px;
			.slide-container {
				padding: 0 20px;
			}

			[class~='slick-slide'] {
				position: relative;
				opacity: 0.4;

				&[class~='slick-active'] {
					opacity: 1;
				}

				@media only screen and (max-width: (@screen-xs)) {
					padding: 0 15px;
				}
			}

			[class~='slick-arrow'] {
				z-index: 1;

				&[class~='slick-disabled'] {
					opacity: 0.6;
					cursor: no-drop;
				}
			}

			[class~='slick-prev'],
			[class~='slick-next'] {
				width: 63px;
				height: 63px;

				@media only screen and (max-width: (@screen-tablet-max)) {
					width: 53px;
					height: 53px;
				}

				@media only screen and (max-width: (@screen-mobile-max)) {
					display: none !important;
				}

				&::before {
					content: '';
					opacity: 1;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
				}
			}

			[class~='slick-prev'] {
				left: calc(25% - 10px);

				@media only screen and (max-width: (@screen-tablet-max)) {
					left: 40px;
				}

				&::before {
					background-image: url('/icons/icon-arrow-left-circle.svg');
				}
			}

			[class~='slick-next'] {
				right: calc(25% - 10px);

				@media only screen and (max-width: (@screen-tablet-max)) {
					right: 40px;
				}

				&::before {
					background-image: url('/icons/icon-arrow-right-circle.svg');
				}
			}

			[class~='slick-dots'] {
				height: fit-content;
				top: 100%;
				margin-top: 30px;
				padding: 0 20px;

				@media only screen and (max-width: (@screen-xs)) {
					margin-top: 20px;
				}

				li {
					button {
						position: relative;
						width: 16px;
						height: 16px;

						&::before {
							content: '';
							opacity: 1;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-image: url('/icons/icon-dot.svg');
							background-size: cover;
							background-repeat: no-repeat;
							background-position: center;
						}
					}

					&[class~='slick-active'] {
						button {
							&::before {
								background-image: url('/icons/icon-dot-active.svg');
							}
						}
					}
				}
			}
		}
	}
}

.card-banner {
	position: relative;
	height: 0;
	width: 100%;
	padding-bottom: 47%;

	.content-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		.image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';