.section-premium-container {
	padding: 100px 0;
	background-image: url('/images/main-bg-2.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	.image {
		max-width: 100%;
		height: auto;
	}

	h2 {
		color: @white;
		text-align: center;
		font-size: 48px;
		font-weight: 700;

		margin-bottom: 34px;
		margin-top: -90px;

		@media only screen and (max-width: (@screen-tablet-max)) {
			font-size: 36px;
		}
		@media only screen and (max-width: (@screen-mobile-max)) {
			font-size: 32px;
		}
		@media only screen and (max-width: (@screen-sm)) {
			margin-top: -18%;
		}

		span {
			color: #f5c763;
		}
	}

	.subscribe-btn {
		min-width: 223px;
		width: fit-content;
		height: 46px;
		max-width: 100%;
		border-radius: 4px;
		border: 1px solid #1467e4;
		background: var(--Gradient-Blue, linear-gradient(270deg, #0267ff 0%, #004aba 90.5%));
		box-shadow:
			0px 0px 5px 0px #0263f4 inset,
			0px 2px 4px 0px rgba(18, 16, 72, 0.5);
		color: @white;
		font-size: 20px;
		font-weight: 500;
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';