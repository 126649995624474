.home-container {
	padding-top: 84px;

	@media only screen and (max-width: @screen-mobile-max) {
		padding-top: 55px;
	}

	.main-banners {
		margin-bottom: 50px;
	}

	.carousel-games-section {
		padding-top: 50px;
		padding-bottom: 50px;

		@media only screen and (max-width: (@screen-lg)) {
			margin-top: 98px;
		}
		@media only screen and (max-width: (@screen-mobile-max)) {
			margin-top: 66px;
		}

		.title {
			width: 73%;
			margin: 0 auto;
			margin-bottom: 45px;
			color: @white;
			text-align: center;
			font-size: 48px;
			font-weight: 700;

			@media only screen and (max-width: (@screen-lg)) {
				font-size: 36px;
			}
			@media only screen and (max-width: (@screen-mobile-max)) {
				width: 100%;
				font-size: 32px;
			}

			span {
				color: #0267ff;
			}
		}
	}

	.floor-section {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

body:has([class~='header-connect-wallet']) {
	.home-container {
		padding-top: 116px;

		@media only screen and (max-width: @screen-mobile-max) {
			padding-top: 87px;
		}
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';